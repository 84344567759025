html,
#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Inconsolata";
  src: local("Inconsolata"),
    url(../src/app/assets/fonts/Inconsolata-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"),
    url(../src/app/assets/fonts/IBMPlexMono-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../src/app/assets/fonts/Inter-Regular.ttf) format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Inter' !important;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  font-family: 'Inter';
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// margin
$margin: 15px;

// backgrounds
$primary-color: #FF9A23;
$secondary-color: #000000;

// fonts
$primary-font-color: #FFFFFF;
$secondary-font-color: #FF9A23;

// border
$border-color: rgba(255, 255, 255, 0.4);

// sidemenu
$menus-wrap-color: rgba(17, 17, 17, 1);