// @import '../../../../index.scss';

.pwd-success-container {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 7%;
    left: -43%;
    top: 12%;


    .placeholder {
        color: rgba(0, 0, 0, 0.50);
        text-align: center;
        font-family: Inter;
        font-size: 1.9vmin;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
        /* 26.884px */
        margin: 0 10% 0 10%;
        letter-spacing: 0.04px;

    }

    .register {
        text-transform: uppercase;
    }

    .icons {
        display: flex;
        padding-top: 5%;
        margin: auto;

    }

    .input-group {
        padding: 5% 0 5% 0;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .pwd-success-container {
        top: 0;
        left: 0;
        width: 85%;

        .icons {
            height: 85px;
        }
    }
}

@media only screen and (min-width:601px)and (max-width:768px) {
    .pwd-success-container {
        top: 28%;
        left: -43%;
        width: 135%;

        .icons {
            height: 85px;
        }

        .pwd-line {
            padding-top: 0 !important;
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .pwd-success-container {
        top: 28%;
        left: -43%;
        width: 135%;

        .icons {
            height: 95px;
        }

        .pwd-line{
            padding-top: 0 !important;
        }
    }
}