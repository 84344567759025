@import '../../App.scss';

#gst-details-container {
    .z-gst-info-wrap {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        max-width: 100%;

        .info-list-wrap {
            width: 21%;
            height: 90vh;
            max-height: 90vh;
            background-color: #fff;
            overflow: auto;

            .search_cover {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 10%;
                justify-content: space-evenly;
                position: relative;
                top: 1%;



                .icon {
                    font-size: 3vmin;
                    margin-top: 2%;
                    cursor: pointer;
                    // border: 1.5px solid $secondary-color;
                    padding: 1%;
                    border-radius: 2px;
                    color: $secondary-color;
                }


                .z-table_global_search {
                    border-radius: 20px;
                    width: 65% !important;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }

                .gst_add_btn {
                    background-color: $primary-color;
                    padding: 0%;
                    margin: 0%;
                    height: 4vh;
                    width: 2.2vw;

                    img {
                        height: 3vh;
                    }
                }
            }

            .list_item_wrap {
                height: 90%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: auto;

                .info-card {
                    min-height: 22vh;
                    height: auto;
                    width: 90%;
                    // margin: auto;
                    margin-bottom: 6%;
                    border-radius: 6px;
                    // box-shadow: 5px 8px 10px 0px rgba(0, 0, 0, 0.07);
                    box-shadow: 7px 5px 10px 5px rgba(0, 0, 0, 0.0705882353);
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                    cursor: pointer;
                    color: $primary-color;
                    padding: 2% 1%;

                    .cnt_badge {
                        color: #fff;
                        margin-left: auto;

                        span {
                            background-color: $primary-color;

                        }
                    }

                    .info-title {
                        font-weight: 600;
                        font-size: 1.7vmin;
                    }

                    .type_cover {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;

                        .type_wrap {
                            height: 2.7vh;
                            width: auto;
                            padding: 0% 2%;
                            // margin-left: 3% !important;
                            min-width: 20%;
                            background-color: $secondary-color;
                            color: #fff;
                            border-radius: 10px;
                            font-size: 1.3vmin;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .tax_wrap {
                            height: 2.7vh;
                            width: auto;
                            padding: 0% 2%;
                            // margin-right: 3% !important;
                            min-width: 20%;
                            background-color: $secondary-color;
                            color: #fff;
                            border-radius: 10px;
                            font-size: 1.3vmin;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .date-cover {
                        width: 100%;
                        height: 45%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;

                        .date-cell {
                            width: 50%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;

                            .date_label {
                                font-size: 1.8vmin;
                            }

                            .date_value {
                                font-size: 2vmin;
                                font-weight: bold;
                            }
                        }
                    }

                    &:hover {
                        .date-cover {
                            .date-cell {

                                .date_value {
                                    color: $secondary-color;
                                }
                            }
                        }

                        .info-title {
                            color: $secondary-color;
                        }
                    }

                    &:first-child {
                        margin-top: 5%;
                    }
                }

                .mark-border {
                    background-color: #FFF7F3;
                    border-left: 6px solid $primary-color;
                }
            }
        }


        .info-details-wrap {
            width: 79%;
            height: 90vh;
            max-height: 90vh;
            overflow: auto;
            // background-color: #F5F5F5;

            .overview_menu_wrap {
                height: 6vh;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: end;
                justify-content: flex-end;
            }

            .info-details-wrap-cover {

                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .info-header {
                    height: 7vh;
                    width: 95%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    .info-title {
                        font-weight: 600;
                        font-size: 2.1vmin;
                        color: #000;
                        margin-top:0%;
                    }

                    .query_wrap {
                        height: 60%;
                        width: auto;
                        padding: 0% 2%;
                        margin-left: 2%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        border: 1px solid #0d497f;
                        background: #a3c9eb;
                        color: #0d497f;
                        font-weight: bold;
                        font-size: 2.1vmin;
                    }
                }

                .task-info-wrap {
                    height: auto;
                    min-height: 10vh;
                    width: 95%;
                    border-radius: 6px;
                    background-color: #fff;
                    color: #000;
                    box-shadow: 5px 8px 10px 0px rgba(0, 0, 0, 0.07);
                    display: flex;
                    flex-direction: column;
                    transition: 0.5s;

                    .task-info-header {
                        height: 10vh;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;

                        .icon {
                            margin-left: 1%;
                            transition: 0.5s;
                            color: $secondary-color;
                        }

                        .task-info-title {
                            font-size: 1.9vmin;
                            margin-left: 1%;
                        }

                        .view-container {
                            width: 60%;
                            height: 80%;
                            display: flex;
                            margin-left: auto;
                            margin-right: 1%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @keyframes pulse {
                                0% {
                                    transform: scale(1);
                                }

                                50% {
                                    transform: scale(1.2);
                                }

                                100% {
                                    transform: scale(1);
                                }
                            }

                            .get-btn {
                                animation: pulse 1.2s;
                                width: 40%;
                                height: 65%;
                                color: #FFF;
                                // background: rgb(165, 166, 167);
                                margin-left: 10px;
                                // border-radius: 20px;
                                font-size: 1.8vmin;
                                border-radius: 42px;
                                background: $secondary-color;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .dropdown-btn {
                                width: 45%;

                                .ant-radio-group {
                                    display: flex;
                                    flex-direction: column;
                                }

                                .ant-select-selector {
                                    height: 45px;
                                    align-items: center;
                                }
                            }

                            .save-btn {
                                width: 23%;
                                // height: 65%;
                                color: #FFF;
                                // background: rgb(165, 166, 167);
                                margin-left: 10px;
                                // border-radius: 20px;
                                font-size: 1.8vmin;
                                border-radius: 42px;
                                background: #424B70;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                    .task-info-content-wrap {
                        min-height: 35vh;
                        height: max-content;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-evenly;

                        .task-details-tile {
                            width: 42%;
                            min-height: 2vh;
                            height: max-content;
                            padding-bottom:1%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border-bottom: 1px solid rgb(236, 237, 238);

                            .details-tile-label {
                                width: 40%;
                                color: rgb(85, 85, 85);
                                font-size: 1.9vmin;
                            }

                            .details-tile-value {
                                max-width: 60%;
                                font-size: 2.1vmin;
                            }
                        }
                    }


                }

                .sub-tabs-cover-wrap {
                    min-height: auto;
                    max-height: 73vh;
                    height: auto;
                    width: 95%;
                    margin-top: 1vh;
                    margin-bottom: 4vh;

                    .sub-tabs-cover-header {
                        height: 7vh;
                        // background-color: #F5F5F5;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .sub-tab {
                            font-weight: 500;
                            margin-left: 2%;
                            line-height: 3.5vmin;
                            cursor: pointer;
                            color: #000;
                        }

                        .mark {
                            border-bottom: 2px solid $secondary-color;
                        }
                    }

                    .sub-tabs-content {
                        min-height: 12vh;
                        max-height: 66vh;
                        height: auto;
                        border-radius: 6px;
                        color: #000;
                        padding: 2%;
                        background-color: #fff;
                        overflow: auto;

                        .details-tab-wrap {
                            width: 100%;
                            display: flex;
                            flex-direction: row;

                            .attachment {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: start;
                                position: relative;

                                .attach_ul {
                                    // margin: auto 0%;
                                    width: 85%;
                                    margin-right: 5%;
                                }

                                .sub-tab-title {
                                    width: 100%;
                                    margin-bottom: 3%;
                                    text-decoration: underline;
                                    text-decoration-color: $secondary-color;
                                }

                                .reply-title {
                                    margin-left: 8%;
                                    margin-bottom: 3%;
                                    top: 0%;
                                }

                                .list_no {
                                    font-size: 2vmin;
                                    margin-right: 2%;
                                }

                                .click-me {
                                    color: #0170C0;
                                    cursor: pointer;
                                    text-decoration: none;
                                    display: flex;
                                    word-break: break-word;
                                    font-size: 2vmin;

                                    .attach-icon {
                                        height: 3px;
                                        padding: 0 10px 0;
                                    }
                                }

                                li {
                                    margin-top: 0.5%;
                                    word-wrap: break-word;
                                    display: flex;
                                }

                                .reply_cover {
                                    margin: auto 0%;
                                    width: 90%;

                                    .task-details-tile {
                                        width: 100%;
                                        min-height: 7vh;
                                        height: auto;
                                        display: flex;
                                        flex-wrap: wrap;
                                        flex-direction: row;
                                        align-items: center;
                                        border-bottom: 1px solid rgb(236, 237, 238);

                                        .details-tile-label {
                                            width: 30%;
                                            color: rgb(85, 85, 85);
                                            font-size: 2vmin;
                                        }

                                        .details-tile-value {
                                            font-size: 2vmin;
                                            width: 70%;
                                        }

                                        .details-attach-value {
                                            font-size: 2vmin;
                                            width: 80%;
                                            min-height: 4vh;
                                            height: auto;
                                            display: flex;
                                            flex-direction: column;
                                            margin-left: auto;
                                            align-items: center;

                                            .list_no {
                                                font-size: 2vmin;
                                                margin-right: 2%;
                                                white-space: nowrap;
                                                color: #000;


                                            }

                                            &:first-child {
                                                margin-top: 4%;
                                            }

                                            &:last-child {
                                                margin-bottom: 4%;
                                            }

                                        }

                                        .link {
                                            color: #0170C0 !important;
                                            cursor: pointer;
                                            width: 70%;
                                            word-break: break-word;
                                            align-items: start;

                                            a {
                                                color: #0170C0 !important;
                                                text-decoration: none !important;
                                                min-width: 20vw;


                                            }

                                        }
                                    }
                                }
                            }

                            .divider {
                                height: auto;
                                border-right: 2px solid rgb(236, 237, 238);
                            }
                        }
                    }

                    .support-container {
                        margin-top: 0% !important;
                    }
                }
            }
        }
    }
}


.css-cyxlny-MuiPaper-root-MuiDialog-paper {
    max-width: 90vw !important;
}