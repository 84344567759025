// @import "../../../../index.scss";
@import '../../../App.scss';

.video-popup {
    width: 70% !important;
    height: 60vh;

    .ant-modal-title {
        font-size: 3vmin !important;
    }
}

.sync-report-popup {
    .ant-modal-title {
        font-size: 3vmin !important;
        text-align: center;
    }

    .report-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        text-align: end;
        margin-top: 9%;
        min-height: 43vh;

        .report-title {
            width: 75%;
            display: flex;
            flex-direction: column;
            align-items: end;
        }

        h3 {
            line-height: 0.5;
        }

        .report-cnt {
            color: $secondary-color;
            width: 15%;
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
        }
    }
}

.container-login {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 7%;
    left: -43% !important;


    .login-btn {
        display: flex;
        width: 85%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin: auto;
        cursor: pointer;
        border-radius: 8px;
        background: #424B70;
        border-style: none;

        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }

    .login-btn-new {
        width: 55% !important;
        background: #E96919;
        text-decoration: none;
    }

    .fields {
        margin: 0 5% 0 5%;
        padding-bottom: 5%;

        .row {
            .input-group {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;

                .forgot-pwd {
                    color: #000;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                    /* 187.5% */
                    text-decoration-line: underline;
                    padding-top: 3%
                }

                .otp-cover {
                    width: 100%;
                    max-width: 100% !important;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;

                    .otp_input {
                        height: 80%;
                        width: 13%;
                        padding: 0px !important;
                    }
                }

            }

            .btn_grp {
                margin-top: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                .teminal-submit-btn {
                    display: flex;
                    height: 7vmin !important;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    cursor: pointer;
                    border-radius: 8px;
                    background: $primary-color;
                    border-style: none;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.3vmin;
                    font-style: normal;
                    font-weight: 400;
                }

                .teminal-submit-secondary-btn {
                    display: flex;
                    height: 7vmin !important;
                    width: 45%;
                    padding: 13px 0px;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    cursor: pointer;
                    border-radius: 8px;
                    background: #FFF;
                    border: 1px solid $secondary-color;
                    color: $secondary-color;
                    font-family: Inter;
                    font-size: 2.3vmin;
                    font-style: normal;
                    font-weight: 400;
                }
            }

            .radio-group {
                margin-top: 1.2rem !important;
                display: flex;
                flex-direction: column;
                width: 50%;
                margin: auto;
            }

            .forgot-link {
                display: flex;
                margin: auto;
                justify-content: center;
                text-align: center;
                // margin-top: 4.1%;
                width: 100%;
            }
        }

        .line {
            border: 1px solid black;
        }
    }

    // .input-box {
    //     height: 3rem;
    //     border-radius: 4px;
    //     border: 0.5px solid $font-secondary-color;
    //     margin-top: 0.4rem;
    //     .icons {
    //         position: relative;
    //         top: 17%;
    //         left: 2%;
    //     }

    //     .eye_icon {
    //         position: absolute;
    //         color: #77787A;
    //         right: 8%;
    //         margin-top: 2%;
    //         cursor: pointer;
    //     }

    .eye_icon {
        position: absolute;
        color: #77787A;
        right: 8%;
        margin-top: 2%;
        cursor: pointer;
    }

    // span {
    //     color: #E10000;
    //     font-family: Inter;
    //     font-size: 14px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 142%;
    //     /* 19.88px */
    //     letter-spacing: 0.035px;
    //     padding-top: 1%;
    //     float: right !important;
    // }
}

.role-contain {
    top: 17% !important;
}

input[type="text"],
input[type="password"] {
    border: 0;
    outline: none;
    width: 85%;
    position: relative;
    top: 4%;
    height: 90%;
    margin-left: 4%;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

// }

@media only screen and (min-width:320px) and (max-width:600px) {
    .container-login {
        top: 0;
        left: 0 !important;
        width: 85%;

        .fields{
            .row{
                .btn_grp{
                    margin-top: 1% !important;
                    .teminal-submit-btn {
                        font-size:3.3vmin;
                        font-weight: bold;
                    }
                    .teminal-submit-secondary-btn{
                        font-size:3.3vmin;
                        font-weight: bold;
                    }
                }
            }
        }

        .row {
            .input-group {
                margin-top: 0.5rem !important;

                .otp-cover {
                    .otp_input {
                        width: 15% !important;
                        height: 45px !important;
                    }
                }
            }

            .error-margin {
                margin-top: 0 !important;
            }

            .terminal-divider {
                padding-top: 0.3rem !important;
            }
        }
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .container-login {
        width: 130%;
        top: 12%;
        left: -40%;
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .container-login {
        width: 125%;
        top: 11%;
        left: -42%;
    }
}