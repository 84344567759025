// @import "../../../../index.scss";

.container-fgt-pwd {
    // border-radius: 4px;
    // background: #FFF;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    // position: relative;
    // // width: 140%;
    // top: 10%;
    // left: -20%;


    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 7%;
    left: -43%;

    // label{
    //     color: #000;
    //     font-family: Inter;
    //     font-size: 1.7vmin;
    //     font-weight: 400;
    // }


    .login-btn {
        display: flex;
        width: 85%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #424B70;
        border-style: none;
        cursor: pointer;

        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }

    .login-btn-new {
        width: 55% !important;
        background: #E96919;
    }

    .fields {
        margin: 0 5% 0 5%;
        padding-bottom: 5%;

        .row {
            .input-group {
                margin-top: 2rem;
                display: flex;
                flex-direction: column;

                .forgot-pwd {
                    color: #000;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    /* 187.5% */
                    text-decoration-line: underline;
                    padding-top: 3%
                }
            }

            .forgot-link {
                display: flex;
                margin: auto;
                justify-content: center;
            }
        }

        .line {
            border: 1px solid black;
        }
    }

    // .input-box {
    //     height: 3rem;
    //     border-radius: 4px;
    //     border: 0.5px solid rgba(0, 0, 0, 0.70);
    //     margin-top:  0.4rem;

    //     img {
    //     position: relative;
    //     top: 17%;
    //     left: 2%;
    //     }

    //     span {
    //         color: #E10000;
    //         font-family: Inter;
    //         font-size: 14px;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: 142%;
    //         /* 19.88px */
    //         letter-spacing: 0.035px;
    //         padding-top: 1%;
    //         float: right !important;
    //     }

    // }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .container-fgt-pwd {
        top: 0;
        left: 0;
        width: 85%;

        .line-margin {
            margin: 0 !important;
        }

        .row {
            .input-group {
                margin-top: 0.8rem !important;
            }

            .error-margin {
                margin-top: 0.2rem !important;
            }
        }
    }
}

@media only screen and (min-width:500px) and (max-width:600px) {
    .container-fgt-pwd {
        width: 80%;
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .container-fgt-pwd {

        width: 125%;
        top: 23%;
        left: -37%;

        .fields {
            .row {
                .input-group {
                    margin-top: 1rem;
                }
            }
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .container-fgt-pwd {
        width: 125%;
        top: 21%;
        left: -42%;

        .input-group {
            margin-top: 1.5rem !important;
        }

        .error-margin {
            margin-top: 1rem !important;
        }
    }
}

@media only screen and (min-width:1025px) and (max-width:1300px) {}