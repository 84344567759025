@import '../../App.scss';

#avail-gst-dashboard {
    height: 92%;
    margin-top: 1%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .avail-menu-container {
        height: 100%;
        width: 13%;
        display: flex;
        flex-direction: column;

        .menu_btn {
            color: var(--low-contrast-text, $font-secondary-color);
            font-size: 1.9vmin;
            font-weight: 400;
            background: #fff;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 8%;
            border-radius: 0px;
            gap: 5px;
            min-width: 10%;
            width: auto;
            margin-bottom: 5%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .menu_btn_active {
            background: $secondary-color !important;
            color: #fff !important;
            font-weight: 600 !important;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-left: 5px solid #fff;
        }
    }

    .avail-content-container {
        height: 98%;
        width: 87%;
        overflow: auto;

        .applied-loans-cover {
            height: 98%;
            width: 96.5%;
            margin-left: 1%;
            // display: flex;
            flex-direction: column;
            background-color: #fff;
            align-items: center;
            overflow: auto;

            .task-info-wrap {
                height: auto;
                min-height: 10vh;
                width: 95%;
                border-radius: 6px;
                background-color: #fff;
                color: #000;
                box-shadow: 5px 8px 10px 0px rgba(0, 0, 0, 0.07);
                display: flex;
                flex-direction: column;
                transition: 0.5s;
                margin-top: 2%;
                margin-left: auto;
                margin-right: auto;
                border-bottom: 1px solid $primary-color;

                .task-info-header {
                    height: 10vh;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;

                    .icon {
                        margin-left: 1%;
                        transition: 0.5s;
                        color: $secondary-color;
                    }

                    .task-info-title {
                        font-size: 1.9vmin;
                        margin-left: 1%;
                    }

                    .view-container {
                        width: 45%;
                        height: 80%;
                        display: flex;
                        margin-left: auto;
                        margin-right: 1%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .get-btn {
                            width: 40%;
                            height: 65%;
                            color: #FFF;
                            // background: rgb(165, 166, 167);
                            margin-left: 10px;
                            // border-radius: 20px;
                            font-size: 1.8vmin;
                            border-radius: 42px;
                            background: $secondary-color;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .dropdown-btn {
                            width: 45%;

                            .ant-radio-group {
                                display: flex;
                                flex-direction: column;
                            }

                            .ant-select-selector {
                                height: 45px;
                                align-items: center;
                            }
                        }

                        .save-btn {
                            width: 23%;
                            // height: 65%;
                            color: #FFF;
                            // background: rgb(165, 166, 167);
                            margin-left: 10px;
                            // border-radius: 20px;
                            font-size: 1.8vmin;
                            border-radius: 42px;
                            background: #424B70;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .task-info-content-wrap {
                    height: auto;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    padding-bottom: 2%;

                    .task-details-tile {
                        width: 42%;
                        min-height: 9vh;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid rgb(236, 237, 238);

                        .details-tile-label {
                            width: 40%;
                            color: rgb(85, 85, 85);
                            font-size: 1.9vmin;
                        }

                        .details-tile-value {
                            max-width: 60%;
                        }


                    }
                }

                &:last-child {
                    margin-bottom: 2%;
                }

            }
        }

        .sole-poprietor-cover {
            height: 98%;
            width: 96.5%;
            margin-left: 1%;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            align-items: center;
            justify-content: center;

            img {
                filter: drop-shadow(5px 5px 10px rgb(174, 149, 226));
                border:1px solid rgba(102, 51, 153);
                border-radius: 4px;
                padding:1%;
            }
        }

        .corporate-container {
            height: 98%;
            width: 96.5%;
            margin-left: 1%;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            align-items: center;

            .form-header {
                height: 7%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .head-line {
                    color: black;
                    font-size: 2.3vmin;
                    font-weight: bold;
                }
            }

            .field-list-wrap {
                height: 93%;
                overflow: auto;
                width: 90%;
                color: black;
                display: flex;
                flex-direction: column;

                .field-cover {
                    min-height: 9vh;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;

                    .field-type-label {
                        font-weight: bold;
                        margin-right: 4%;
                        max-width: 40%;
                    }

                    .field-label {
                        font-weight: bold;
                        margin-right: 4%;
                        max-width: 40%;
                        min-width: 40%;
                    }

                    .upload-input-cover {
                        display: flex;
                        min-width: 56%;
                        width: auto;
                        flex-wrap: wrap;
                        align-items: center;

                        .attach_file_line {
                            text-decoration: underline;
                            color: #3488FF;
                            font-size: '2.3vmin';

                            text-decoration-color: #3488FF;
                            cursor: pointer;
                        }

                        .selection_list {
                            display: flex;
                            min-width: 20vw;
                            width: auto;
                            margin-left: 7%;
                        }
                    }
                }

                .radio-grp {
                    min-width: 19vw;
                    display: flex;
                    flex-direction: row;

                    .first-radio {
                        margin-left: 1%;
                    }
                }

                .submit-footer {
                    max-width: 100%;
                    min-height: 7vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: auto;

                    .clear_btn {
                        width: 10%;
                        height: 70%;
                        color: $secondary-color;
                        font-size: 2.2vmin;
                        margin-left: 2%;
                        border: 1px solid $secondary-color;
                    }

                    .submit_btn {
                        width: 10%;
                        height: 70%;
                        color: #fff;
                        font-size: 2.2vmin;
                        margin-left: 1%;
                        margin-right: 1%;
                        background-color: $primary-color;
                    }
                }
            }
        }
    }




}

#confirm_container {

    .MuiPaper-root {
        width: 30% !important;
        max-width: 70% !important;
        // top: -15%;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 2.5vmin !important;
        font-style: normal;
        font-weight: 600 !important;
        padding-left: 5%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
    }


    .confirm-head {
        justify-content: left !important;
    }

    .confirm-msg {
        display: flex;
        justify-content: left;
        color: #000;
        font-family: Inter !important;
        font-size: 2vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 168.023% !important;
        /* 50.407px */
        margin-bottom: 5%;
    }

    .no-btn {
        position: relative;
        left: 2%;
        background: #424B70 !important;
    }

    .confirm-btn {
        display: flex;
        float: right;
        width: 25%;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #ed7d21;
        border-style: none;
        cursor: pointer;
        color: #FFF;
        font-family: Inter;
        font-size: 1.5vmin;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #avail-gst-dashboard {

        flex-direction: column;

        .avail-menu-container {
            height: 10vh !important;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            .menu_btn {
                font-size: 2.5vmin;
                align-items: center;
                justify-content: center;
                height: 70%;
                min-width: 28%;
                width: auto;
            }

            .menu_btn_active {
                border-top-right-radius: 0px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                border-left: 2px solid #fff;
            }
        }

        .avail-content-container {
            height: 90%;
            width: 100%;

            overflow: auto;
            justify-content: center;
            align-items: center;
            display: flex;

            // //////applied loan screen///////////////////////////////////
            .applied-loans-cover {


                .task-info-wrap {


                    .task-info-header {


                        .icon {}

                        .task-info-title {
                            font-size: 2vmin;

                        }

                        .view-container {


                            .get-btn {}

                            .dropdown-btn {

                                .ant-radio-group {}

                                .ant-select-selector {}
                            }

                            .save-btn {}
                        }
                    }

                    .task-info-content-wrap {
                        height: 60vh;

                        .task-details-tile {
                            width: 50%;


                            .details-tile-label {
                                width: 40%;
                                color: rgb(85, 85, 85);
                                font-size: 2.3vmin;
                            }

                            .details-tile-value {
                                max-width: 60%;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 2%;
                    }

                }
            }

            .sole-poprietor-cover {
                height: 98%;
                width: 96.5%;
                margin-left: 1%;
                display: flex;
                flex-direction: column;
                background-color: #fff;
                align-items: center;
            }

            .corporate-container {


                .form-header {


                    .head-line {}
                }

                .field-list-wrap {
                    height: 93%;
                    overflow: auto;
                    width: 90%;
                    color: black;
                    display: flex;
                    flex-direction: column;

                    .field-cover {
                        padding-bottom: 5%;

                        .field-type-label {}

                        .field-label {

                            font-size: 3vmin;
                        }

                        .upload-input-cover {


                            .attach_file_line {

                                font-size: 2.2vmin;


                            }

                            .selection_list {}
                        }
                    }

                    .radio-grp {
                        min-width: 50vw;
                        width: 70vw !important;

                        .first-radio {}
                    }

                    .submit-footer {

                        .clear_btn {
                            width: 13%;

                        }

                        .submit_btn {
                            width: 13%;

                        }
                    }
                }
            }
        }




    }


}

@media only screen and (min-width:600px) and (max-width:768px) {
    #avail-gst-dashboard {

        flex-direction: column;

        .avail-menu-container {
            height: 10% !important;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            .menu_btn {
                font-size: 2vmin;
                align-items: center;
                justify-content: center;
                height: 70%;
                min-width: 28%;
                width: auto;
            }

            .menu_btn_active {

                border-top-right-radius: 0px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                border-left: 2px solid #fff;
            }
        }

        .avail-content-container {
            height: 90%;
            width: 100%;

            overflow: auto;
            justify-content: center;
            align-items: center;
            display: flex;

            // //////applied loan screen///////////////////////////////////
            .applied-loans-cover {


                .task-info-wrap {


                    .task-info-header {


                        .icon {}

                        .task-info-title {
                            font-size: 2vmin;

                        }

                        .view-container {


                            .get-btn {}

                            .dropdown-btn {

                                .ant-radio-group {}

                                .ant-select-selector {}
                            }

                            .save-btn {}
                        }
                    }

                    .task-info-content-wrap {
                        height: 60vh;

                        .task-details-tile {
                            width: 50%;


                            .details-tile-label {
                                width: 40%;
                                color: rgb(85, 85, 85);
                                font-size: 2.3vmin;
                            }

                            .details-tile-value {
                                max-width: 60%;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 2%;
                    }

                }
            }

            .sole-poprietor-cover {
                height: 98%;
                width: 96.5%;
                margin-left: 1%;
                display: flex;
                flex-direction: column;
                background-color: #fff;
                align-items: center;
            }

            .corporate-container {


                .form-header {

                    .head-line {}
                }

                .field-list-wrap {


                    .field-cover {
                        padding-bottom: 5%;

                        .field-type-label {}

                        .field-label {

                            font-size: 2vmin;
                        }

                        .upload-input-cover {


                            .attach_file_line {

                                font-size: 2vmin;


                            }

                            .selection_list {}
                        }
                    }

                    .radio-grp {
                        min-width: 50vw;

                        .first-radio {}
                    }

                    .submit-footer {

                        .clear_btn {
                            width: 13%;

                        }

                        .submit_btn {
                            width: 13%;

                        }
                    }
                }
            }
        }




    }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
    #avail-gst-dashboard {


        .avail-menu-container {
            width: 25%;

            .menu_btn {

                font-size: 1.5vmin;

            }

            .menu_btn_active {}
        }

        .avail-content-container {


            .applied-loans-cover {


                .task-info-wrap {


                    .task-info-header {

                        .icon {}

                        .task-info-title {
                            font-size: 2vmin;

                        }

                        .view-container {


                            .get-btn {}

                            .dropdown-btn {

                                .ant-radio-group {}

                                .ant-select-selector {}
                            }

                            .save-btn {}
                        }
                    }

                    .task-info-content-wrap {


                        .task-details-tile {


                            .details-tile-label {}

                            .details-tile-value {}
                        }
                    }

                    &:last-child {}

                }
            }

            .sole-poprietor-cover {}

            .corporate-container {


                .form-header {


                    .head-line {
                        font-size: 2.1vmin;
                    }
                }

                .field-list-wrap {


                    .field-cover {

                        padding-bottom: 6%;

                        .field-type-label {}

                        .field-label {
                            width: 25% !important;
                            font-size: 1.8vmin;
                            max-width: 25%;
                            min-width: 25%;
                        }

                        .upload-input-cover {
                            display: flex;
                            min-width: 66%;
                            width: auto;
                            flex-wrap: wrap;
                            align-items: center;

                            .attach_file_line {
                                text-decoration: underline;
                                color: #3488FF;
                                font-size: 1.5vmin;

                                text-decoration-color: #3488FF;
                                cursor: pointer;
                            }

                            .selection_list {}
                        }
                    }

                    .radio-grp {
                        min-width: 50vw;


                        .first-radio {
                            margin-left: 1%;
                        }
                    }

                    .submit-footer {


                        .clear_btn {
                            width: 15%;

                            font-size: 2vmin;

                        }

                        .submit_btn {
                            width: 15%;

                            font-size: 2vmin;

                        }
                    }
                }
            }
        }




    }
}

@media only screen and (min-width:1025px) and (max-width:1440px) {
    #avail-gst-dashboard {


        .avail-menu-container {
            width: 20%;


            .menu_btn {

                font-size: 2vmin;

            }

            .menu_btn_active {}
        }

        .avail-content-container {
            width: 80%;


            .applied-loans-cover {


                .task-info-wrap {


                    .task-info-header {


                        .icon {}

                        .task-info-title {}

                        .view-container {


                            .get-btn {}

                            .dropdown-btn {

                                .ant-radio-group {}

                                .ant-select-selector {}
                            }

                            .save-btn {}
                        }
                    }

                    .task-info-content-wrap {


                        .task-details-tile {


                            .details-tile-label {

                                font-size: 2vmin;
                            }

                            .details-tile-value {}
                        }
                    }

                    &:last-child {}

                }
            }

            .sole-poprietor-cover {}

            .corporate-container {


                .form-header {


                    .head-line {}
                }

                .field-list-wrap {


                    .field-cover {


                        .field-type-label {}

                        .field-label {
                            font-size: 1.8vmin;
                            max-width: 35%;
                            min-width: 35%;
                        }

                        .upload-input-cover {


                            .attach_file_line {

                                font-size: 2vmin;

                            }

                            .selection_list {}
                        }
                    }

                    .radio-grp {
                        min-width: 50vw;


                        .first-radio {}
                    }

                    .submit-footer {


                        .clear_btn {
                            width: 15%;

                        }

                        .submit_btn {
                            width: 15%;

                        }
                    }
                }
            }
        }




    }
}

@media only screen and (min-width:1441px) {
    #avail-gst-dashboard {


        .avail-menu-container {
            width: 20%;


            .menu_btn {

                font-size: 2vmin;

            }

            .menu_btn_active {}
        }

        .avail-content-container {
            width: 80%;


            .applied-loans-cover {


                .task-info-wrap {


                    .task-info-header {


                        .icon {}

                        .task-info-title {}

                        .view-container {


                            .get-btn {}

                            .dropdown-btn {

                                .ant-radio-group {}

                                .ant-select-selector {}
                            }

                            .save-btn {}
                        }
                    }

                    .task-info-content-wrap {


                        .task-details-tile {


                            .details-tile-label {

                                font-size: 2vmin;
                            }

                            .details-tile-value {}
                        }
                    }

                    &:last-child {}

                }
            }

            .sole-poprietor-cover {}

            .corporate-container {


                .form-header {


                    .head-line {}
                }

                .field-list-wrap {


                    .field-cover {
                        min-height: 11vh;


                        .field-type-label {}

                        .field-label {
                            font-size: 2vmin !important;
                            max-width: 35%;
                            min-width: 35%;
                        }

                        .upload-input-cover {


                            .attach_file_line {

                                font-size: 2vmin;

                            }

                            .selection_list {}
                        }
                    }

                    .radio-grp {
                        min-width: 50vw;


                        .first-radio {}
                    }

                    .submit-footer {


                        .clear_btn {
                            width: 15%;

                        }

                        .submit_btn {
                            width: 15%;

                        }
                    }
                }
            }
        }




    }
}