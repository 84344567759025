
/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#due-date-container {

    .gst-container{
        height: 100%;
        width: 100%;

        gap:2%;
    
    }
    .gst-header{
        
        height: 24vh;
        display: flex;
        position: relative;
        flex-direction: column;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);
        
    }
    .table_global_search{
        border-radius: 20px;
        width: 58%;
        height: 40px !important;
        background: rgb(243, 243, 249);

        input{
            background: rgb(243, 243, 249);
        }
    }
    .nav-button{
        display: flex;
        color: #666666;
        position: absolute;
        right:10%
    }
    .head-top{
        display: flex;
        position: relative;
        width: 100%;
        height:12vh;
        border-bottom-width: 2px;
    }
    .head-bottom{
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
        height:12vh;
        border-bottom-width: 2px;
    }
    .profile-content {
        color: black;
        font-family: Inter;
        display: flex;
        align-items: center;
        position: absolute;
        right: 8px;
        font-size: 25px;
        margin-top: 14px;
    }
    .gst-stats{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 38%;
    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination {
        display: flex;
        flex-wrap: wrap;
        flex-direction: inherit;
        row-gap: 8px;
    }
    .stat-item{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .stat-subs{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .stat-sub{
        display: flex;
        flex-direction: row;
        align-items: center;
        width:75%
    }
    .stats-box{
        border: 1px solid #00000099;
        height: 90%;
        width: 18%;
        border-radius: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }


    :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
        font-size: 25px;
        font-weight: 600;
        color: #031742;
    }

    #gst-chart{

        border: 1px solid rgb(0 0 0 / 29%);

        width: 40%;
        border-radius: 1%;
        display: flex;
    flex-direction: row;
    justify-content: space-around;
    }
    .gst-chart{
        width: 35%;
        height: 85%;
        margin-left: 3%;
        
    }
    .stats-box img{
        height:35%
    }
    .chart-stat{
        width:50%;
        display:flex;
        flex-direction: column;
    }
    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right:3%
    }
    .gst-table{

    display: flex;
    justify-content: center;
    flex-direction: column;
    width:96%;
    margin-top: 1%;
    padding: 2%;

    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
        position: relative;
        z-index: 1;
        flex: 1;
        width: max-content;
    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead >tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: start;
        background: #0000001a;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }
    .gst-table-container{
        width: 100.1%;
        margin-left: 0;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1) translate(-50%,-75%);
    }
    
    // .ant-pagination-total-text {
    //     position: absolute;
    //     left: 0;
    // }
    // .ant-pagination-options {
    //     position: absolute;
    //     right: 0;
    // }


    
    .stats-box p{
        font-family: Inter;
    }
    .right-container{
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 3%;
        gap: 3%;
        align-items: center;
        height: 100%;
    }
    #gst-add-button{
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap:2px;
        height:60%;
        font-size: 16px;
    }
    #gst-solution-button{
        background:#ED7D31;
        font-size: 16px;
        color: white;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 3% 2%;
        border-color: #ED7D31;
        
    }

    .count{
        font-weight: 600;

    }
    .table-container{
        display: flex;
        width:100%;
        flex-direction: row;
        align-items: center;
        border: 0.5px solid rgba(0,0,0,0.50);
        border-bottom: none;
    }
    .left-container{
        gap: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 50%;
        margin-left: 2%;

    }
    .gst-row-filter{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        align-items: center;
        
        flex-direction: row;
        
    }
    .gst-row-item{
        display: flex;
        flex-direction: row;
        gap:13%;
        width: 23%;
        margin-bottom: 3%;
        align-items: center;

    }
    .gst-row-item img{
        height:15px
    }


}