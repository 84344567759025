@import '../../App.scss';

.input-group {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    // padding-bottom: 1rem;

    span {
        float: left;
    }

    .register {
        text-transform: uppercase;
    }

    .gst-err-msg {
        margin-top: 1% !important;
    }

    label {
        color: #000;
        font-family: Inter;
        font-size: 1.8vmin;
        font-weight: 400;
    }


    .input-box {
        height: 3rem;
        border-radius: 4px;
        border: 0.5px solid rgba(0, 0, 0, 0.70);


    }

    span {
        float: right;
        font-size: 1.7vmin;
        color: rgb(225, 0, 0);
    }
}

.latest_wrap {
    height: 83vh;
    width: 100%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;

    .latest_header {
        height: 14vh;
        max-width: 100%;
        margin: 0% 2%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        .first_row {
            max-width: 100%;
            height: 60%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0% 2%;

            span {
                color: #000;
            }

            .right_cover {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                width: 28%;

                .search {
                    border-radius: 20px;
                    width: 80%;
                    height: 40px !important;
                    background: rgb(243, 243, 249);

                    input {
                        background: rgb(243, 243, 249);
                        font-family: Inter;
                    }
                }

                .gst_add_btn {
                    background-color: $primary-color;
                    padding: 0%;
                    margin: 0%;
                    height: 4vh;
                    width: 2.2vw;

                    img {
                        height: 3vh;
                        max-height: 70%;
                    }
                }
            }
        }

        .second_row {
            max-width: 100%;
            height: 40%;
            padding: 0% 2%;
            display: flex;
            flex-direction: row;

            .sub_action {
                cursor: pointer;
                color: $primary-color;
                font-size: 2vmin;
                font-weight: bold;
                padding: 1%;
                display: flex;
                align-items: center;
                border-radius: 8px;
                margin-right: 1%;
                min-width: 10%;
                border: 1px solid $primary-color;


                span {
                    margin-right: 2%;
                    color: $secondary-color;
                }
            }

            .active {
                border: 1px solid $primary-color;
                background-color: #E7F1FA;
            }
        }
    }

    .content_wrap {
        height: 65vh;
        max-width: 100%;
        background-color: #fff;
        margin: 0% 2%;
        display: flex;
        flex-direction: row;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;

        .blog_list_wrap {
            margin-top: 1%;
            margin-bottom: 1%;
            width: 17%;
            min-height: 60vh;
            border-right: 1px solid $secondary-color;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;

            .blocks {
                width: 80%;
                min-height: 4vh;
                cursor: pointer;
                color: $primary-color;
                font-size: 2vmin;
                font-weight: bold;
                padding: 1%;
                padding-left: 4%;
                display: flex;
                align-items: center;
                border-radius: 8px;
                margin-bottom: 2%;

                span {
                    margin-right: 2%;
                    color: $secondary-color;
                }
            }

            .active {
                border: 1px solid $primary-color;
                background-color: #E7F1FA;
            }
        }

        .content_gallery {
            min-height: 55vh;
            margin-bottom: 1%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            .article_showcase {
                height: 98%;
                width: 97%;
                margin: auto;
                margin-right: 0%;
                padding: 0% 1%;
                display: flex;
                flex-direction: column;
                overflow: auto;

                .head_wrap {
                    min-height: 9vh;
                    height: max-content;
                    width: 100%;
                    border-bottom: 1px solid $primary-color;
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    justify-content: space-evenly;
                    padding: 2% 0%;

                    .head_lines {
                        font-weight: bold;
                        font-size: 2vmin;
                        color: $primary-color;
                    }

                    .date_lines {
                        font-size: 1.9vmin;
                        color: $font-secondary-color;
                    }
                }

                .rel_content_wrap {
                    height: max-content;
                    margin-bottom: 1%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .html_style {
                        white-space: normal;
                        color: black;
                        font-weight: 600;
                        margin: 0% 2%;
                        font-size: 2vmin;
                    }

                    .artcl_attachmnt {
                        cursor: pointer;
                        color: $primary-color;
                        border: 1px solid #a9a9a9;
                        border-radius: 4px;
                        padding: 10px;
                        font-size: 1.8vmin;
                        display: flex;
                        align-items: center;

                        .download_icn {
                            color: $secondary-color;
                        }

                        &:hover {
                            border: 1px solid #000;

                        }
                    }
                }

                .feedback_wrap {
                    height: 7vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #000;
                    font-size: 2vmin;
                    margin-top: auto;
                    border-top: 1px solid $primary-color;

                    .like_btn {
                        color: $primary-color;
                        margin: 0% 1%;
                        cursor: pointer;
                    }

                    .dislike_btn {
                        cursor: pointer;
                        margin: 0% 1%;
                        color: $secondary-color;
                    }
                }
            }

            .content_row {
                width: 90%;
                min-height: 7vh;
                height: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
                color: #000;
                font-weight: bold;
                font-size: 2vmin;
                margin-bottom: 0.5%;
                border-radius: 5px;
                border-bottom: 1px solid #cdcbcb;

                .content_subject {
                    color: $primary-color;

                    .s_no {
                        color: #000;
                    }

                    .icon {
                        height: 20px;
                    }

                }

                .content_desc {
                    color: $secondary-color;
                    margin-left: 2%;
                    cursor: pointer;
                }

                a {
                    color: #3F8DFF;
                    cursor: pointer;
                    font-weight: normal;
                    text-decoration: underline;
                }

                .edit_icn {
                    cursor: pointer;
                    height: 20px;
                    color: $primary-color;
                    margin-left: auto;
                }

                .delete_icn {
                    cursor: pointer;
                    height: 20px;
                    color: $secondary-color;
                }

                &:hover {
                    border-bottom: 2.5px solid #cdcbcb;
                }
            }
        }

        .relevant_folders {
            width: 25%;
            height: 61vh;
            margin-top: 1%;
            margin-bottom: 1%;
            display: flex;
            flex-direction: column;
            padding: 0% 0.5%;
            border-left: 1px solid $secondary-color;

            .head_lines {
                font-weight: bold;
                font-size: 2.2vmin;
                color: $primary-color;
            }

            .rel_content_wrap {
                min-height: 58vh;
                margin-bottom: 1%;
                overflow: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .content_row {
                    width: 90%;
                    min-height: 7vh;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;
                    color: #000;
                    font-weight: bold;
                    font-size: 2vmin;
                    margin-bottom: 1%;
                    border-radius: 5px;
                    border-bottom: 1px solid #cdcbcb;

                    .content_subject {
                        color: $primary-color;

                        .s_no {
                            color: #000;
                        }

                        .icon {
                            height: 20px;
                        }

                    }

                    .content_desc {
                        color: $secondary-color;
                        margin-left: 2%;
                        cursor: pointer;
                    }



                    a {
                        color: #3F8DFF;
                        cursor: pointer;
                        font-weight: normal;
                        text-decoration: underline;
                    }

                    .edit_icn {
                        cursor: pointer;
                        height: 20px;
                        color: $primary-color;
                        margin-left: auto;
                    }

                    .delete_icn {
                        cursor: pointer;
                        height: 20px;
                        color: $secondary-color;
                    }

                    &:hover {
                        border-bottom: 2.5px solid #cdcbcb;
                    }
                }

                .active {
                    border-bottom: 2.5px solid $primary-color;
                }
            }
        }
    }
}