@import '../../App.scss';

@keyframes fadeOut {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.Notification_cover {
    height: 91vh;
    top: 7vh;
    right: 4.5vw;
    width: 30vw;
    position: absolute;
    border-radius: 10px;
    z-index: 10;
    border: 0.5px solid $primary-color;
    background-color: rgb(255, 255, 255);

    .head_box {
        height: 7vh;
        width: 100%;
        border-bottom: 0.5px solid $primary-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;

        .head_line {
            font-size: 2.4vmin;
            font-weight: bold;
            color: $secondary-color;
            width: 100%;
        }

        .sub-header-wrap {
            width: 100%;
            height: 60%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;

            .sync-box {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: 5%;

                .sync-btn {
                    background: rgb(236, 251, 228);
                    border: 1px solid rgb(82, 196, 26);
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    height: 90%;
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-weight: 500;
                }

                .Progres-btn {
                    background: #BEDCE7;
                    border: 1px solid #5FAABC;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    height: 5.3vh;
                    color: var(--text-black, #393938);
                    font-family: Inter;
                    font-size: 1.8vmin;
                    font-weight: 500;
                    cursor: pointer;

                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(360deg);
                        }
                    }

                    .Progress-icon {
                        animation: rotation 1.5s;
                        animation-iteration-count: infinite;
                    }
                }
            }

            .link-item {
                font-size: 2vmin;
                font-weight: bold;
                text-decoration: underline;
                text-decoration-color: $primary-color;
                color: $primary-color;
                cursor: pointer;
                text-align: start;
                margin-right: 5%;
            }
        }
    }

    .notify_content_cover {
        height: 79vh;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: auto;

        .content_block {
            min-height: 12vh;
            height: auto;
            width: 94%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 2% 2% 0%;
            border-bottom: 1px solid #c6c6c6;
            color: #000;

            .avatar {
                background-color: $secondary-color;
                text-transform: uppercase;
                cursor: pointer;
                max-height: 7.4vh;
                height: 7.4vh;
                max-width: 3.7vw;
                width: 3.7vw;
            }

            .content-msg-cover {
                width: 100%;
                margin-left: 2%;
                margin-right: 2%;
                color: $primary-color;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;

                .content_msg {
                    font-size: 2.2vmin;
                    word-wrap: break-word;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: max-content;
                    .vlu{
                        margin-top: 2%;
                        color: $secondary-color;
                        font-weight: bold;

                    }
                }


            }

            .content_stamp {
                font-size: 1.8vmin;
                // color: #aaa;
                color: #242424;
                margin-left: auto;
                margin-right: 2%;
            }

            &:hover {
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
                border-bottom: 1.5px solid $primary-color;
                border-radius: 4px;

                .content-msg-cover {
                    // color: #fff;
                }
            }

            &:last-child {
                margin-bottom: 1%;
            }
        }

        .more_btn{
            color:$secondary-color;
            margin:2% 2% 2% auto;
            padding: 2%;
            background-color: $secondary-color;
            color: #fff;
            border-radius: 4px;
            cursor: pointer;
        }
    }

    &.fade-out {
        animation: fadeOut 0.6s forwards;
    }
}

.sync-report-popup {
    .ant-modal-title {
        font-size: 3vmin !important;
        text-align: center;
    }

    .ant-modal-footer {
        margin-top: 6px !important;
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: white !important;
    }

    .report-view-button {
        background: #424B70;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        width: 40%;
        height: 5vh;
        font-size: 2vmin;
    }

    .report-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        text-align: end;
        margin-top: 3%;
        min-height: 43vh;

        .report-title {
            width: max-content;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        h3 {
            line-height: 0.5;
        }

        .report-cnt {
            color: $secondary-color;
            max-width: 50%;
            width: max-content;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding-left: 2%;
        }
    }

    .footer_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .dtail_btn {
            background-color: $primary-color;
            color: #fff;
            border: none;
            cursor: pointer;
            padding-right: 0;
            font-weight: bold;

            &:hover {
                background-color: $primary-color !important;
                color: #fff !important;
            }
        }
    }
}

.upload-popup {
    .ant-table-wrapper {
        .ant-table {
            scrollbar-color: auto !important;
        }

        .ant-spin-nested-loading {
            min-height: 30vh !important;
        }
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    .Notification_cover {
        width: 70vw;

        .head_box {
            height: 10vh;

            .head_line {
                font-size: 4.4vmin;
            }

            .sub-header-wrap .link-item {
                font-size: 3.5vmin;
            }
        }
    }

    .sync-report-popup .footer_wrap {
        flex-direction: column;
    }
}