.password-container {
    // border-radius: 4px;
    // background: #FFF;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    // position: relative;
    // // width: 140%;
    // top: 10%;
    // left: -20%;


    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 122%;
    top: 7%;
    left: -43%;


    .err_span {
        padding-bottom: 1%;
        padding-top: 0 !important;
    }

    .fields {
        margin: 0 5% 0 5%;
        padding-bottom: 5%;

        .input-group {
            margin-top: 1rem;
        }
    }

    .err-msg {
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        font-family: Inter;
        font-size: 1.8vmin;
        font-style: normal;
        font-weight: 550;
        line-height: 2.7vmin;
    }


    .input-box {
        //     height: 3rem;
        //     border-radius: 4px;
        //     border: 0.5px solid rgba(0, 0, 0, 0.70);
        //     margin-top: 0.4rem;

        .err-msg {

            //         display: flex;
            //         flex-direction: column;
            //         margin-top: 2%;
            span {
                color: #E10000;
                font-family: Inter;
                font-size: 1.5vmin;
                font-style: normal;
                font-weight: 400;
                line-height: 142%;
                /* 19.88px */
                letter-spacing: 0.035px;
                padding-top: 1%;
                float: left !important;
            }

        }

        //     img {
        //     position: relative;
        //     top: 17%;
        //     left: 2%;
        //     }

        //     .eye_icon {
        //         position: absolute;
        //         color: #77787A;
        //         right: 12%;
        //         margin-top: 2%;
        //         cursor: pointer;

        .err-container {
            display: flex;
            align-items: center;
            justify-content: left;
        }
    }

    .err-msg-span {
        color: #E10000;
        font-family: Inter;
        font-size: 1.5vmin;
        font-style: normal;
        font-weight: 400;
        line-height: 142%;
        /* 19.88px */
        letter-spacing: 0.035px;
        padding-top: 1%;
        float: left !important;
    }

    // }

    input[type="password"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }

    input[type="text"] {
        border: 0;
        outline: none;
        width: 85%;
        position: relative;
        top: 4%;
        height: 90%;
        margin-left: 4%;
    }

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

}

@media only screen and (min-width:320px) and (max-width:600px) {
    .password-container {
        top: 0;
        left: 0;
        width: 85%;

        .input-group {
            margin-top: 0.5rem !important;
        }

        .second-input {
            margin-top: 6rem !important;
        }

        .err_span {
            padding-bottom: 0 !important;
            padding-top: 2% !important;
        }

        .set-btn {
            margin-top: 2.5rem !important;
            margin-bottom: 3%;
        }

        .err-msg-span {
            font-size: 2.7vmin !important;
        }

        .err-msg span {
            font-size: 2.7vmin !important;
        }
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .password-container {
        width: 130%;
        top: 10%;
        left: -40%;

        .second-input {
            margin-top: 7rem !important;
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .password-container {
        top: 10%;
        width: 123%;
        left: -39%;

        .second-input {
            margin-top: 7rem !important;
        }
    }
}