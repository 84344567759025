@import '../../App.scss';


#notice-details-container {

    .notice-upper-container {
        // width: 100%;
        // display: flex;

        .top-notice-container {
            display: flex;

            .notice-left-container {
                display: flex;
                flex-direction: row;
                width: 50%;
                // display: flex;
                // justify-content: flex-start;

                .back-nav {
                    border: 1px solid $primary-color;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    font-size: 2.1vmin;
                    font-weight: bold;
                    width: auto;
                    margin-left: 4%;

                    .icon {
                        font-size: 2.3vmin;
                    }
                }

                .header {
                    width: 15%;
                    color: #232323;
                    font-family: Inter;
                    font-size: 3.5vmin;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .view-container {
                    width: 85%;
                    display: flex;

                    .dropdown-btn {
                        width: 45%;

                        .ant-radio-group {
                            display: flex;
                            flex-direction: column;
                        }

                        .ant-select-selector {
                            height: 45px;
                            align-items: center;
                        }
                    }

                    .save-btn {
                        width: 20%;
                        // height: 45px;
                        color: #FFF;
                        // background: rgb(165, 166, 167);
                        margin-left: 10px;
                        // border-radius: 20px;
                        font-size: 1.8vmin;
                        border-radius: 42px;
                        background: #424B70;
                    }
                }

            }

            .action-right-container {
                width: 50%;
                display: flex;
                justify-content: flex-end;

                .gst-solution-btn {
                    // margin-left: 10px;
                    border-radius: 7px;
                    // height: 6%;
                    color: white;
                    background: #ED7D31;
                    // position: absolute;
                    // right: 3%;
                    padding: 3% 2%;
                    align-items: center;
                    display: flex;
                    top: 35%;

                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .view-solution-btn {
                    border-radius: 7px;
                    // height: 6%;
                    color: white;
                    background: #ED7D31;
                    // position: absolute;
                    // right: 3%;
                    padding: 3% 2%;
                    align-items: center;
                    display: flex;
                    top: 35%;

                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .mobile-top-notice-container {
            display: none;
        }
    }

    .view-gst-header {

        border: 1px solid black;
        height: 24vh;
        display: flex;
        justify-content: center;
        color: red($color: #000000);
    }

    .notice-upper-container {
        padding: 2%;
        padding-top: 0%;
    }

    // .notice-upper-container span{
    //     font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    // }
    .notice-upper-container .header {
        font-weight: 500;
        font-size: 25px;
        color: black
    }

    .checkbox-container {
        display: flex;

        gap: 2%;
        align-items: center;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        border-color: #1677ff;
        background-color: #ED7D31;
    }

    .ant-radio .ant-wave-target .ant-radio-checked {
        background-color: red;
    }


    :where(.css-dev-only-do-not-override-byeoj0).ant-radio-wrapper {
        margin-bottom: 4%;
    }

    .view-notice-main {

        .view-notice-header-wrap {
            height: 15vh;
            width: 96%;
            background-color: #FFF;
            margin: 2%;
            margin-bottom: 0%;
            border-radius: 16px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .content-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                width: max-content;
                height: 100%;

                .main-line {
                    font-size: 2.2vmin;
                    line-height: 3.5vmin !important;
                    font-size: bold;
                    color: #424B70;
                    font-family: 'Inter';
                    margin: 0;
                }

                .sub-line {
                    font-size: 2.2vmin;
                    line-height: 1.8vmin;
                    font-family: 'Inter';
                    margin: 0;
                }
            }

            .view-icon {
                color: $primary-color;
                cursor: pointer;
            }

        }

        .z-table {
            padding-top: 0% !important;
        }

    }



}


.reply-container {
    min-height: 30vh !important;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .task-details-tile {
        width: 100%;
        min-height: 7vh;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid rgb(236, 237, 238);

        .details-tile-label {
            width: 30%;
            color: rgb(85, 85, 85);
            font-size: 2vmin;
        }

        .details-tile-value {
            font-size: 2.2vmin;
        }

        .details-attach-value{
            font-size: 2.2vmin;
            width: 80%;
            height: 4vh;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            align-items: center;
        }

        .link{
            color: #3F8DFF;
            cursor: pointer;
        }
    }

    .list_no {
        font-size: 2.3vmin;
    }

    .click-me {
        color: #0170C0;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        word-break: break-word;
        font-size: 2vmin;

        .attach-icon {
            height: 3px;
            padding: 0 10px 0;
        }
    }

    li {
        margin-top: 0.5%;
        word-wrap: break-word;
        display: flex;
    }

}

@media only screen and (min-width:320px) and (max-width:600px) {
    #gst-details-container {
        .notice-upper-container {
            .top-notice-container {
                display: none;
            }

            .mobile-top-notice-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100% !important;

                .back-nav {
                    margin-right: 1%;
                    border: 1px solid $primary-color;
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    font-size: 2.1vmin;
                    width: auto;
                    padding: 4px 5px;
                    height: 60%;

                    .icon {
                        font-size: 3vmin;
                    }
                }

                .view-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 60%;

                    .dropdown-btn {
                        font-size: 3vmin !important;

                        .dropdown-btn-selection {
                            width: 80vw !important;
                        }

                        .dropdown-options {
                            width: 80vw !important;
                        }

                        .ant-select-dropdown-hidden {
                            width: 40% !important;
                        }
                    }

                    .save-btn {
                        width: 20%;
                        height: 50%;
                        color: #FFF;
                        font-size: 1.8vmin;
                        border-radius: 42px;
                        background: #424B70;
                        align-items: center;
                        text-align: center;
                    }
                }


                .gst-solution-btn {
                    margin-left: auto;
                    border-radius: 7px;
                    color: white;
                    background: #ED7D31;
                    padding: 3% 2%;
                    align-items: center;
                    display: flex;
                    top: 35%;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 2.2vmin;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .view-notice-main {}




    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    #gst-details-container .view-notice-main .notice-details .notice-content {
        gap: 10% !important;

        ul {
            width: 17% !important;
        }
    }
}

@media only screen and (min-width:1441px) {
    #gst-details-container {
        .notice-upper-container {
            .top-notice-container {
                .notice-left-container .view-container {
                    .save-btn {
                        height: 5vh !important;
                    }
                }
            }

            .mobile-top-notice-container {
                display: none;
            }
        }

        .view-notice-main {

            .child-div {
                .attachment {
                    .notice-header {}

                    ul {
                        margin-top: 10% !important;
                    }

                    li {}
                }
            }
        }

    }
}