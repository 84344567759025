@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import '../../App.scss';

#solution-dashboard-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .crumbs_wrap {
        width: 100vw;
    }

    .sol_table_wrap {
        width: 74.5vw;
        height: 81.5vh;
        overflow: auto;
        margin-top: 1%;

        .z-table {
            width: 98%;
            padding: 0%;
            padding-left: 2%;
        }
    }

    .success_stories_wrap {
        width: 24.9vw;
        height: 81.5vh;
        margin-top: 1%;
        border-left: 1px dashed $secondary-color;

        .updates_head_wrap {
            height: 4vh;
            width: 98%;
            padding: 0% 2%;
            color: #031742;
            border-bottom: 1px solid $secondary-color;
            font-weight: bold;
            text-align: center;
        }

        .updates_content_wrap {
            height: 76.5vh;
            overflow: auto;
            text-align: center;

            .content_row {
                width: 90%;
                margin: auto;
                min-height: 7vh;
                height: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
                color: #000;
                font-weight: bold;
                font-size: 2vmin;
                margin-bottom: 0.5%;
                padding:  2% 0%;
                border-radius: 5px;
                border-bottom: 1px solid #cdcbcb;

                .content_subject {
                    color: $primary-color;

                    .s_no {
                        color: #000;
                    }

                    .icon {
                        height: 20px;
                    }

                }

                .content_desc {
                    color: $secondary-color;
                    margin-left: 2%;
                    cursor: pointer;
                }

                a {
                    color: #3F8DFF;
                    cursor: pointer;
                    font-weight: normal;
                    text-decoration: underline;
                }

                .edit_icn {
                    cursor: pointer;
                    height: 20px;
                    color: $primary-color;
                    margin-left: auto;
                }

                .delete_icn {
                    cursor: pointer;
                    height: 20px;
                    color: $secondary-color;
                }

                &:hover {
                    border-bottom: 2.5px solid #cdcbcb;
                }
            }
        }
    }

    .gst-container {
        height: 100%;
        width: 100%;

        gap: 2%;

    }

    .stats-box p {
        font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-modal .ant-modal-title {
        font-size: 25px;
        font-weight: 600;
        color: #031742;
    }

    #gst-chart {
        width: 40%;
        border-radius: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .gst-chart {
        width: 35%;
        height: 85%;
        margin-left: 3%;

    }

    .stats-box img {
        height: 26%
    }

    .chart-stat {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right: 3%
    }

    .gst-table {

        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 96%;
        margin-top: 3%;
        padding: 2%;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
        position: relative;
        z-index: 1;
        flex: 1;
        width: max-content;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: start;
        background: #ECEDF1;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }

    // .ant-pagination-total-text {
    //     position: absolute;
    //     left: 0;
    // }
    // .ant-pagination-options {
    //     position: absolute;
    //     right: 0;
    // }
    .gst-table-container {
        width: 100.1%;
        // margin-left: 4%;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1) translate(-50%, -75%);
    }

    .table_global_search {
        border-radius: 20px;
        width: 58%;
        height: 40px !important;
        background: rgb(243, 243, 249);

        input {
            background: rgb(243, 243, 249);
        }
    }

    .gst-header {

        height: 24vh;
        display: flex;
        justify-content: center;
    }

    .gst-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 38%;
    }

    .stat-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-pagination.ant-pagination {
        margin: 16px 0;
        display: flex;
        justify-content: space-evenly;
    }

    #modelHeader {
        color: #232323 !important;
        font-family: Inter;
        font-size: 3.5vmin !important;
        font-weight: 600 !important;
    }

    .stat-subs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .stat-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75%
    }

    .stats-box {
        border: 1px solid rgba(0, 0, 0, 0.324);
        height: 78%;
        width: 15%;
        border-radius: 4%;
        display: flex;

        align-items: center;
        flex-direction: column;

    }

    .stats-box p {
        font-size: 14px;
    }

    :where(.css-dev-only-do-not-override-190m0jy).ant-select .ant-select-selector {
        width: 100%;
        height: 45px !important;
        padding: 0 11px;
    }


    .stats-box p {
        font-family: Inter;
    }

    .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 0 !important;
        gap: 3%;
        align-items: center;
        height: 100%;
    }

    #gst-add-button {
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap: 2px;
        height: 6vh;
        font-size: 16px;
    }

    #gst-chart {
        width: 40%;
        border-radius: 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .gst-chart {
        width: 35%;
        height: 85%;
        margin-left: 3%;

    }

    .stats-box img {
        height: 26%
    }

    .chart-stat {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .dot {
        height: 12.5px;
        width: 12.5px;
        background-color: #bbb;
        border-radius: 50%;
        display: block;
        margin-right: 3%
    }

    .gst-table {

        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 96%;
        margin-top: 3%;
        padding: 2%;

    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-column-title {
        position: relative;
        z-index: 1;
        flex: 1;
        width: max-content;
    }

    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        text-align: start;
        background: #ECEDF1;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.2s ease;
    }

    .gst-table-container {
        width: 100.1%;
        // margin-left: 4%;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1) translate(-50%, -75%);
    }

    .table_global_search {
        border-radius: 20px;
        width: 58%;
        height: 40px !important;
        background: rgb(243, 243, 249);

        input {
            background: rgb(243, 243, 249);
        }
    }


    h2 {
        // color: black;
        // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        // font-weight: 400;
    }

    .stats-box p {
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }

    .right-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: 0 !important;
        gap: 3%;
        align-items: center;
        height: 100%;
    }

    #gst-add-button {
        background: #424B70;
        color: white;
        display: flex;
        align-items: center;
        gap: 2px;
        height: 6vh;
        font-size: 16px;
    }

    #gst-solution-button {

        background: #424B70;
        font-size: 16px;
        color: white;
        height: 60%;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 1.5%;

    }

    .count {
        font-weight: 600;

    }

    .table-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        // border: 0.5px solid rgba(0,0,0,0.50);
        // border-bottom: none;
        align-items: center
    }

    .left-container {
        gap: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 50%;
        // margin-left: 2%;

    }

    .gst-row-filter {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        align-items: center;

        flex-direction: row;

    }

    .gst-row-item {
        display: flex;
        flex-direction: row;
        gap: 13%;
        width: 23%;
        margin-bottom: 3%;
        align-items: center;

    }

    .gst-row-item img {
        height: 15px
    }


}

@media only screen and (min-width:320px) and (max-width:600px) {
    .ant-modal {

        // padding-left: 10vw;
        margin-left: 2vw;

        .ant-modal-content {
            width: 95%;
            margin-left: 3vw;

            .ant-modal-header {

                .ant-modal-title {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 5vmin !important;
                    width: 90% !important;
                }
            }

            .ant-modal-body {
                .type-field-lable {
                    font-size: 3.2vmin !important;
                }
            }
        }
    }
}

@media only screen and (min-width:601px) and (max-width:768px) {
    .ant-modal {

        .ant-modal-content {
            width: 75vw;
            margin-left: -16vw;

            .ant-modal-header {

                .ant-modal-title {
                    font-size: 3vmin !important;

                }
            }

            .ant-modal-body {
                .type-field-lable {
                    font-size: 2vmin !important;
                }
            }
        }
    }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
    .ant-modal {
        background-color: #424B70;

        .ant-modal-content {
            width: 100%;

            .ant-modal-header {

                .ant-modal-title {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 3.5vmin !important;
                    width: 90% !important;
                }
            }

            .ant-modal-body {
                .type-field-lable {
                    font-size: 2.5vmin !important;
                }
            }
        }
    }
}

@media only screen and (min-width:1026px) and (max-width:1440px) {
    .ant-modal {

        .ant-modal-content {
            width: 100%;

            .ant-modal-header {

                .ant-modal-title {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 3vmin !important;
                    width: 90% !important;
                }
            }

            .ant-modal-body {
                .type-field-lable {
                    font-size: 2.5vmin !important;
                }
            }
        }
    }
}