#home-wrap {
    height: 100vh;
    width: 100%;

    .outlet-cover-wrap {
        height: 90vh;
        width: 100%;
        overflow: auto;
        align-items: center;
        // display: flex;
        // flex-direction: column;
        background-color: #E7F1FA;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #home-wrap{

        .outlet-cover-wrap{
            overflow: auto;
        }
    }
}
@media only screen and (min-width:601px) and (max-width:768px) {
    #home-wrap{

        .outlet-cover-wrap{
            overflow: auto;
        }
    }
}
@media only screen and (min-width:769px) and (max-width:1025px) {
    #home-wrap {
       
    
        .outlet-cover-wrap {
            height: 91vh;
           
        }
    }
}
@media only screen and (min-width:1026px) and (max-width:1440px) {
    #home-wrap {
       
    
        .outlet-cover-wrap {
           
            height: 90vh;
        }
    }
}
@media only screen and (min-width:1441px)  {
    #home-wrap {
       
    
        .outlet-cover-wrap {
            height: 90vh;
           
        }
    }
}