#logout_container {

    .MuiPaper-root {
        width: 23% !important;
        max-width: 70% !important;
        // top: -15%;
        // max-height: none !important;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
        color: #000;
        font-family: Inter !important;
        font-size: 2.5vmin !important;
        font-style: normal;
        font-weight: 600 !important;
        padding-left: 5%;
        line-height: 168.023% !important;
        /* 50.407px */
    }

    .MuiDialogContent-root {
        border-top: 0 !important;
    }


    .logout-head {
        justify-content: left !important;
    }

    .logout-msg {
        display: flex;
        justify-content: left;
        color: #000;
        font-family: Inter !important;
        font-size: 2vmin !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 168.023% !important;
        /* 50.407px */
        margin-bottom: 5%;
    }

    .no-btn {
        position: relative;
        left: 2%;
        background: #424B70 !important;
    }

    .login-btn {
        display: flex;
        float: right;
        width: 25%;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 8px;
        background: #ed7d21;
        border-style: none;
        cursor: pointer;
        color: #FFF;
        font-family: Inter;
        font-size: 1.5vmin;
        font-style: normal;
        font-weight: 400;
        line-height: 168.023%;
    }
}

@media only screen and (min-width:320px) and (max-width:600px) {
    #logout_container {

        .MuiPaper-root {
            width: 60% !important;
            // top: -15%;
            // max-height: none !important;
        }
    
        .MuiTypography-root {
         
            font-size: 3vmin !important;
           
        }
    
        .MuiDialogContent-root {
            border-top: 0 !important;
        }
    
    
        .logout-head {
            justify-content: left !important;
        }
    
        .logout-msg {
          
            font-size: 3vmin !important;
       
        }
    
        .no-btn {
           font-size: 2vmin;
        }
    
        .login-btn {
        
            font-size: 2.5vmin;
        
        }
    }
    
}
@media only screen and (min-width:601px) and (max-width:768px) {
    #logout_container {

        .MuiPaper-root {
            width: 50% !important;
          
            // top: -15%;
            // max-height: none !important;
        }
    
        .MuiTypography-root {
           
            font-size: 3vmin !important;
           
            /* 50.407px */
        }
    
        .MuiDialogContent-root {
            border-top: 0 !important;
        }
    
    
        .logout-head {
            justify-content: left !important;
        }
    
        .logout-msg {
           
            font-size: 2.5vmin !important;
        
        }
    
        .no-btn {
            position: relative;
            left: 2%;
            background: #424B70 !important;
        }
    
        .login-btn {
           
            font-size:2vmin;
         
        }
    }
}
@media only screen and (min-width:769px) and (max-width:1025px) {
    #logout_container {

        .MuiPaper-root {
            width: 40% !important;

            // max-height: none !important;
        }
    
        .MuiTypography-root {
          
            /* 50.407px */
        }
    
        .MuiDialogContent-root {
        }
    
    
        .logout-head {
        }
    
        .logout-msg {
          
        }
    
        .no-btn {
           
        }
    
        .login-btn {
           
        }
    }
}
@media only screen and (min-width:1026px) and (max-width:1440PX) {
    #logout_container {

        .MuiPaper-root {
            width: 30% !important;
            max-width: 70% !important;
            // top: -15%;
            // max-height: none !important;
        }
    
     
    }
    
}